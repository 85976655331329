import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import NextButton from '../nextButton';

export const CodeDay = ({ nextProject }) => {
    return (
        <section id="ColbyEat" className="px-4 py-2  mx-auto sm:max-w-xl md:max-w-full md:px-24 md:py-16 lg:max-w-screen-xl lg:px-8 lg:py-20">
            <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
                <div className="lg:order-2 flex flex-col justify-center">
                    <div className="w-full mb-6">
                        <h2 className="max-w-lg mt-2 mb-3 font-sans text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl sm:leading-none">
                            ColbyEat <br className="md:hidden"></br>
                            <span className="bg-blue-100 text-blue-800  align-middle text-base font-semibold md:ml-2 my-auto px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">Personal MERN Full Stack Project</span>
                        </h2>
                        <p className="text-base text-gray-700 dark:text-slate-400 md:text-lg">
                            ColbyEat is a full stack MERN web application that gives a better experience of checking dining menus. It <span className="underline decoration-red-400 decoration-solid decoration-2">scrapes the raw and ordinary dining hall menu</span> and notifies users of daily menu in a beautiful text message. It allows users to <span className="underline decoration-sky-400 decoration-solid decoration-2">store dietary and location preferences</span> so that they can receive notifications of dish items that fit their preferences.
                        </p>
                    </div>
                    <p className="mb-4 dark:text-white text-sm font-bold tracking-widest uppercase">
                        Features
                    </p>
                    <div className="grid space-y-3 dark:text-slate-500 sm:gap-2 sm:grid-cols-1 sm:space-y-0">
                        <ul className="space-y-3">
                            <li className="flex">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                User can sign up and log into the application with Email.
                            </li>
                            <li className="flex">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                Setup profiles with meal and dining hall preferences.
                            </li>
                            <li className="flex">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                Receive text message of daily menu based on preferences.
                            </li>
                            <li className="flex">
                                <span className="mr-1">
                                    <svg
                                        className="w-5 h-5 mt-px text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </span>
                                Options to adjust notification time and frequency.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="lg:order-1">
                    <StaticImage className="object-cover w-full h-80 rounded-md shadow-lg sm:h-96" src="../../assets/pingme.png" alt="A dinosaur" />
                    {/* <img
                        className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                        src="{Image}"
                        alt=""
                    /> */}
                </div>
            </div>
            <div className="flex sm:hidden justify-center">
                <div className="mt-6 lg:mt-16 mx-2 lg:mx-10 grid gap-2 grid-rows-3 justify-center">
                    <div className="flex justify-center">
                        <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-red-300 dark:text-red-900">Full Stack</span>
                        <span className="bg-yellow-200 text-yellow-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-yellow-300 dark:text-yellow-900">Heroku</span>
                    </div>
                    <div className='flex justify-center'>
                        <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-green-300 dark:text-green-900">TailwindCSS</span>
                        <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-green-300 dark:text-green-900">ReactJS</span>
                        <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-green-300 dark:text-green-900">JavaScript</span>
                    </div>
                    <div className='flex justify-center'>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">NodeJS</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">MongoDB</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">Django</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">ExpressJS</span>
                    </div>
                </div>
            </div>
            <div className='hidden sm:flex justify-center'>
                <div className="mt-6 lg:mt-16 mx-2 lg:mx-10 grid gap-2 grid-rows-1 lg:grid-rows-2 justify-center">
                    <div className='flex justify-center'>
                        <span className="bg-red-100 text-red-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-red-300 dark:text-red-900">Full Stack</span>
                        <span className="bg-green-100 text-green-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-green-300 dark:text-green-900">TailwindCSS</span>
                        <span className="bg-green-100 text-green-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-green-300 dark:text-green-900">ReactJS</span>
                        <span className="bg-green-100 text-green-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-green-300 dark:text-green-900">JavaScript</span>
                    </div>
                    <div className='flex justify-center'>
                        <span className="bg-purple-100 text-purple-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">NodeJS</span>
                        <span className="bg-purple-100 text-purple-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">MongoDB</span>
                        <span className="bg-purple-100 text-purple-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">Django</span>
                        <span className="bg-purple-100 text-purple-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-purple-300 dark:text-purple-900">ExpressJS</span>
                        <span className="bg-yellow-200 text-yellow-800 text-sm font-semibold mr-2 px-3 py-0.5 rounded dark:bg-yellow-300 dark:text-yellow-900">Heroku</span>
                    </div>
                </div>
            </div>

            <div className="mt-10 mx-2 lg:mx-10 grid gap-0 grid-cols-2">
                <button type="button" className="justify-self-end w-auto text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-2 focus:outline-none focus:ring-slate-200 font-light md:font-medium rounded-lg text-xs sm:text-sm px-5 py-2 text-center inline-flex items-center dark:bg-slate-700 dark:focus:ring-gray-500 dark:hover:bg-slate-600 mr-2 mb-2">
                    <svg className="mr-2 -ml-1 w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
                    <a target="_blank" rel="noreferrer" href="http://google.com">Source Code</a>
                </button>
                <button type="button" className="justify-self-start w-auto text-black bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-2 focus:outline-none focus:ring-slate-200 font-light md:font-medium rounded-lg text-xs sm:text-sm px-5 py-2 text-center inline-flex items-center dark:bg-[#F7BE38] dark:hover:bg-[#F7BE38]/90 dark:focus:ring-yellow-800 mb-2">
                    <a target="_blank" rel="noreferrer" href="https://summer22-sps-27.appspot.com/">Live Site <svg aria-hidden="true" className="inline ml-1 -mr-1 -mt-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></a>
                </button>
            </div>
            <NextButton nextSection={nextProject} />
        </section>
    );
};

export default CodeDay;