import * as React from 'react';

const NextButton = (props) => {
    const nextSection = props.nextSection;

    // return an anchor tag with the href attribute set to the nextSection
    return (
        <div className="flex mt-4 md:mt-6 justify-center">
            <a href={nextSection} className="justify-self-center text-sky-500 border border-sky-500 hover:bg-sky-500 dark:hover:bg-slate-600 hover:text-white focus:ring-2 focus:outline-none focus:ring-sky-300 font-medium rounded-full text-sm p-1.5 md:p-2.5 text-center flex items-center dark:border-slate-200 dark:text-slate-200 dark:hover:text-white dark:focus:ring-slate-800">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24"><path d="M 11 3 L 11 17.070312 L 6.4296875 12.5 L 4.9296875 14 L 12 21.070312 L 19.070312 14 L 17.570312 12.5 L 13 17.070312 L 13 3 L 11 3 z"></path></svg>
            </a>
        </div>
    );
}

export default NextButton;