import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react'

const Hero = () => {
    return (
        <section className="bg-white dark:bg-slate-900">
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-40 lg:grid-cols-12">
                <div className="mr-auto place-self-center md:-mt-32 lg:col-span-7">
                    <p className="max-w-2xl mb-1 font-light text-sky-600 lg:mb-2 md:text-lg lg:text-xl dark:text-gray-400">
                        Hey there 👋
                    </p>
                    <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-black dark:text-white">
                        I'm Siyuan Peng
                    </h1>
                    <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                        I am a software engineer, a full stack developer, and a Computer Science and Maths double major.
                    </p>
                    <a href="#skills" className="inline-flex items-center justify-center px-5 py-2 mr-3 text-base font-medium text-center text-white rounded-lg bg-gradient-to-bl from-rose-400 via-fuchsia-500 to-indigo-500 hover:bg-gradient-to-bl hover:from-rose-500 hover:via-fuchsia-600 hover:to-indigo-600 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                        Know More
                        <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                            <path d="M505.752,123.582c-8.331-8.331-21.839-8.331-30.17,0L256,343.163L36.418,123.582c-8.331-8.331-21.839-8.331-30.17,0
			s-8.331,21.839,0,30.17l234.667,234.667c8.331,8.331,21.839,8.331,30.17,0l234.667-234.667
			C514.083,145.42,514.083,131.913,505.752,123.582z" />
                        </svg>
                    </a>
                    <a href="#contact" className="inline-flex items-center bg-clip-text justify-center px-5 py-2 text-base font-medium text-center text-transparent  bg-gradient-to-bl from-rose-400 via-fuchsia-500 to-indigo-500 rounded-lg hover:bg-gradient-to-bl hover:from-rose-500 hover:via-fuchsia-600 hover:to-indigo-600 focus:ring-4 focus:ring-gray-100  dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                        Contact Me
                    </a>
                </div>
                <div className="flex -mb-24 lg:hidden place-item-center">
                        <StaticImage className="mx-auto w-2/3 h-5/6" src="../assets/me.png" />
                    </div>
                <div className="hidden lg:-mt-32 lg:col-span-5 lg:flex place-item-center">
                    <StaticImage className="w-5/6 h-5/6" src="../assets/me.png" />
                </div>
            </div>
            <div className="flex mt-10 lg:-mt-40 pb-4 justify-center">
                <div className="grid grid-cols-3 gap-4 content-center">
                    <a target="_blank" rel="noreferrer" href="https://github.com/sdotpeng/">
                        <svg className="w-6 h-6 lg:w-8 lg:h-8 hover:opacity-60 dark:text-slate-100" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
                    </a>
                    <a href="mailto:siyuan.rpeng@gmail.com">
                        <svg className="w-7 h-7 lg:w-9 lg:h-9 -mt-0.5 hover:opacity-60 dark:fill-slate-100" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <path d="M 4 4 C 2.976 4 2.1404375 4.772625 2.0234375 5.765625 L 12 12 L 21.976562 5.765625 C 21.859563 4.772625 21.024 4 20 4 L 4 4 z M 2 7.7519531 L 2 18 C 2 19.105 2.895 20 4 20 L 20 20 C 21.105 20 22 19.105 22 18 L 22 7.7519531 L 12.529297 13.669922 C 12.205297 13.871922 11.794703 13.871922 11.470703 13.669922 L 2 7.7519531 z"></path></svg>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/siyuanrickypeng/">
                        <svg className="w-5 h-5 lg:w-7 lg:h-7 ml-1 hover:opacity-60 dark:fill-slate-100" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801zM.396 7.977h4.976v16.023h-4.976zM2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"></path></svg>
                    </a>
                </div>
            </div>
            <div className="text-slate-400 text-xs -mb-10 flex pb-10 justify-center">
                Powered by GatsbyJS, ReactJS, TailwindCSS &amp; Vercel
            </div>
        </section>
    )
}

export default Hero;