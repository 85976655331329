import * as React from "react";

const Contact = () => {
  return (
    <div id="contact" className="overflow-hidden bg-white dark:bg-gray-900">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col items-center justify-between xl:flex-row">
          <div className="w-full max-w-xl mb-12 xl:pr-16 xl:mb-0 xl:w-7/12">
            <h2 className="max-w-lg mb-6 font-mono text-3xl font-bold tracking-tight text-black dark:text-white sm:text-4xl sm:leading-none">
              .contact()
            </h2>
            <p className="max-w-xl mb-4 text-base text-gray-600 dark:text-gray-400 md:text-lg">
              Have an open position, want to get in touch or just say hello? I'd
              love to hear from you.
            </p>
            <p>
              <a
                href="mailto:siyuan.rpeng@gmail.com"
                aria-label=""
                className="inline-flex items-center font-semibold tracking-wider dark:text-slate-300 transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700"
              >
                Email: siyuan.rpeng@gmail.com
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </p>
            <p>
              <a
                href="mailto:siyuan.rpeng@gmail.com"
                aria-label=""
                className="inline-flex items-center font-semibold tracking-wider dark:text-slate-300 transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700"
              >
                Phone: +1 (207) 660-8703
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </p>
          </div>
          <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
            <div className="relative">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute bottom-0 right-0 z-0 hidden w-32 -mb-8 -mr-20 text-teal-accent-400 lg:w-32 lg:-mr-16 sm:block"
              >
                <defs>
                  <pattern
                    id="766323e1-e594-4ffd-a688-e7275079d540"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#766323e1-e594-4ffd-a688-e7275079d540)"
                  width="52"
                  height="24"
                />
              </svg>
              <div className="relative bg-white  dark:bg-slate-700 rounded shadow-2xl p-7 sm:p-10">
                <h3 className="mb-4 text-xl dark:text-white font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                  Send me a message
                </h3>
                <form
                  id="messageForm"
                  action="https://getform.io/f/d71a5320-4a41-4b76-9b99-11605172aa09"
                  method="POST"
                >
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="name"
                      className="inline-block mb-1 dark:text-slate-200 font-medium"
                    >
                      Name
                    </label>
                    <input
                      placeholder="John Doe"
                      required
                      type="text"
                      className="flex-grow w-full h-12 px-4 mb-2 transition dark:text-white dark:bg-slate-600 dark:border-slate-600 dark:placeholder-slate-400 duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="name"
                      name="name"
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="email"
                      className="inline-block mb-1 dark:text-slate-200 font-medium"
                    >
                      Email
                    </label>
                    <input
                      placeholder="john.doe@example.org"
                      required
                      type="text"
                      className="flex-grow w-full h-12 px-4 mb-2 transition dark:text-white dark:bg-slate-600 dark:border-slate-600 dark:placeholder-slate-400 duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="message"
                      className="inline-block mb-1 dark:text-slate-200 font-medium"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      form="messageForm"
                      rows="4"
                      className="block pt-2 px-4 w-full transition duration-200 text-base shadow-sm  text-gray-900 bg-white rounded border focus:outline-none border-gray-300 dark:bg-slate-600 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white"
                      placeholder="Leave a message..."
                    ></textarea>
                  </div>
                  <div className="mt-4 mb-2 sm:mb-4">
                    <button
                      type="submit"
                    //   data-sitekey="6Lda6HohAAAAAE1R8AY8qpRxZEYJIUCS3VJqVO_e"
                    // g-recaptcha required
                      className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 text-slate-800 dark:text-slate-200  dark:bg-radial-at-r dark:from-sky-400 dark:to-indigo-900 transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    >
                      Send
                    </button>
                  </div>
                  <p className="text-xs text-gray-600 dark:text-slate-400 sm:text-sm">
                    I will get in touch in 48 hours. Thank you!
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
