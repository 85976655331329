import * as React from 'react';
import GoogleSPS from './projects/GoogleSPS';
import CodeDay from './projects/CodeDay';
import JavaIDE from './projects/JavaIDE';
import Biometric from './projects/Biometric';
import ColbyEat from './projects/ColbyEat';

const Projects = () => {
    return (
        <section id="projects" className="bg-white dark:bg-slate-900">
            <h2 className="flex  pt-6 mx-auto text-lg font-mono justify-center text-gray-600 dark:text-slate-400 sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                <p>
                    {"const projects = () => {"}
                </p>
            </h2>
            <GoogleSPS nextProject="#ColbyEat"/>
            <ColbyEat nextProject="#CodeDay"/>
            <CodeDay nextProject="#JavaIDE"/>
            <JavaIDE nextProject="#Biometric"/>
            <Biometric nextProject="#experiences"/>
        </section>
    );
}

export default Projects;