import * as React from 'react'
import NavigationBar from '../components/navigation.js'
import Footer from '../components/footer.js'
import Hero from '../components/hero.js'
import Skills from '../components/skills.js'
import Projects from '../components/projects.js'
import Experience from '../components/experience.js'
import Contact from '../components/contact.js'
import { useState } from "react"

const IndexPage = () => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
      speed: 800,
      speedAsDuration: true,
      easing: 'easeInOutCubic'
    });
  }

  if (typeof window !== "undefined") {
    if (
      localStorage.getItem('color-theme') === 'dark' ||
      (!('color-theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }

  
  const LIGHT_THEME = "light"
  const DARK_THEME = "dark"

  const [theme, setTheme] = useState(LIGHT_THEME)
  const switchTheme = () => {
    if (!document.documentElement.classList.contains(DARK_THEME)) {
      document.documentElement.classList.add(DARK_THEME)
      setTheme(DARK_THEME)
    } else {
      document.documentElement.classList.remove(DARK_THEME)
      setTheme(LIGHT_THEME)
    }
  }

  return (
    <div className="bg-light">
      <NavigationBar></NavigationBar>
      <Hero></Hero>
      <Skills></Skills>
      <Projects></Projects>
      <Experience></Experience>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage