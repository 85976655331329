import * as React from 'react';

const Experience = () => {
    return (
        <div id="experience" className="bg-white dark:bg-slate-900">
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-32">
                <div className="flex flex-col mb-6 lg:flex-row md:mb-10">
                    <div className="lg:w-1/2">
                        <h2 className="max-w-md mb-6 font-mono text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl sm:leading-none xl:max-w-lg">
                            .experience()
                        </h2>
                    </div>
                    <div className="lg:w-1/2">
                        <p className="text-base text-gray-700 dark:text-gray-400 md:text-lg">
                            With strong experience in Python and data science, I was selected in freshman year to conduct one-year CS research. With fruitful results in eye movement biometrics, I <span className="text-sky-400">discovered my profound passion for software development</span>. Since then, I've been learning and working on <span className="text-orange-400 dark:text-red-400">web technologies</span>, and used my <span className="text-red-500 dark:text-lime-400">creativity and skills</span> to built projects that <span className="text-indigo-400">help people live better with technology</span>.
                        </p>
                    </div>
                </div>
                <ol className="relative border-l border-gray-200 dark:border-gray-700">
                    <li className="mb-10 ml-4">
                        <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-600"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">June - August 2022</time>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Software Engineering Intern @ CodeDay Labs</h3>
                        <ul className="ml-4 list-outside list-disc">
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">
                                Collaborated within a team to design and implement an automated <a href="#CodeDay" className="underline">Checklist</a> web application in ReactJS, Prisma, GraphQL, and RedwoodJS.
                            </li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">
                                Responsible for frontend development, conducted code review, made 5 version iterations, supervised by Principle Engineer at Microsoft
                            </li>
                        </ul>
                    </li>
                    <li className="mb-10 ml-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-600"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">May - August 2022</time>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Software Engineering Participant @ Google Software Product Sprint</h3>
                        <ul className="ml-4 list-outside list-disc">
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">
                                Design and developed a full stack web application <a href="#projects" className="underline">PingMe</a> that reports emergency faster in Java Servlet, JavaScript, TailwindCSS, Google Cloud Platform, App Engine, and Maps API. Collaborated within a team of four, supervised by Google Engineer.
                            </li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">
                                Contributed to open-source in Git, integrated data storage, lead distributed development, and conducted code review and testing.
                            </li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400">
                                Practiced technical interview with Google Coaches, trained data structure and algorithms, received feedback from mock interviews.
                            </li>
                        </ul>
                    </li>
                    <li className="mb-10 ml-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-600"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">May - August 2022</time>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Certificate in Advanced Technical Interview Prep @ CodePath</h3>
                        <ul className='ml-4 list-outside list-disc'>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Reviewed and practiced advanced data structures and algorithms to prepare for technical interview, four hours lecture and <a href="https://github.com/sdotpeng/LeetCode" className="underline" target="_blank" rel="noreferrer">many hours of praticing</a> per week.</li>
                        </ul>
                    </li>
                    <li className="mb-10 ml-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-600"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Jan 2021 - June 2022</time>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">CS Research Assistant @ Software Engineering and Human Factors Laboratory</h3>
                        <ul className='ml-4 list-outside list-disc'>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Improved eye tracking biometric accuracy from 60% to 73% by building machine learning pipelines on eye movement data with cognitive and lingustical features. <a href="#Biometric" className="underline">Best Technical Abstract Award</a>  at ETRA 2022.</li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Reduced gaze correction time by 28% by leading and developing an semi-automated correction tool with machine learning algorithms, Fix8. Submitted to ETRA 2022.</li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Improved post-experiement data processing efficiency by 40% less time by designing and developing an Eye Tracking Extension on VS Code that calculates association between eye movement data and code.</li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">News: <a href="https://news.colby.edu/story/can-eye-movement-unlock-new-levels-of-security/" className="underline">Can Eye Movement Unlock New Levels of Security?</a></li>
                        </ul>
                    </li>
                    <li className="ml-4">
                    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-600 dark:bg-gray-600"></div>
                        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Aug 2020 - May 2024</time>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Bachelor in Computer Science {"&"} Mathematics Double Major @ Colby College</h3>
                        <ul className='ml-4 list-outside list-disc'>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Courses: Data Structure & Algorithms, Data Analysis & Visualization, Object Oriented Design, Neural Network, Analysis of Algorithms, Computational Theory, Programming Languages, Discrete Math, Probability, Linear Algebra</li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Awards: <a href="https://www.davisuwcscholars.org/" target="_blank" rel="noreferrer" className="underline">Davis Scholar</a>, Presidential Scholar, Dean List</li>
                            <li className="text-base font-normal text-gray-500 dark:text-gray-400 mb-2">Organizations: Colby Data Science Club</li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
    )
}

export default Experience;